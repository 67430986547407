import { getLocation } from 'connected-react-router';
import * as React from 'react';
import { Translate } from 'react-localize-redux-dep-updated';
import { matchPath } from 'react-router';
import { Button, Dialog, Heading, IconInfo, IconQuestionBubble, Modal } from '../../../brenger-shared-ui';
import { Config } from '../../../config';
import { useTypedSelector } from '../../../hooks';
import { r } from '../../../routes';
import { trackEvent } from '../../../utils/eventTracking';
import { translate } from '../../../utils/localization';

export const HelpModal: React.FC = () => {
  const [isModalActive, setIsModalActive] = React.useState(false);
  const [isFcInit, setIsFcInit] = React.useState(false);
  const { pathname } = useTypedSelector(getLocation);
  const homeSituation = useTypedSelector(state => state.generalTransport.choices?.home_situation);

  // Decide what transkey to use base on pathname
  let transKey =
    {
      [r.generalFlow.destination.index()]: '_destination',
      [r.generalFlow.items.index()]: '_items',
      [r.generalFlow.pickup.index()]: '_pickup',
      [r.generalFlow.pickup.floor()]: '_pickup_home_and_floor',
      [r.generalFlow.pickup.invoice()]: '_pickup_invoice',
      [r.generalFlow.pickup.auction()]: '_pickup_auctions',
      [r.generalFlow.time.index()]: '_time',
      [r.generalFlow.delivery.index()]: '_delivery',
      [r.generalFlow.time.delivery()]: '_time',
      [r.generalFlow.delivery.floor()]: '_delivery_home_and_floor',
      [r.generalFlow.date.index()]: '_pickup_date',
      [r.generalFlow.date.delivery()]: '_delivery_date',
      [r.generalFlow.contact.index()]: '_contact',
      [r.generalFlow.contact.pickup()]: '_contact_pickup',
      [r.generalFlow.contact.delivery()]: '_contact_delivery',
      [r.generalFlow.terms.index()]: '_terms',
      [r.generalFlow.productPayment.index()]: '_pay_product',
      [r.generalFlow.productPayment.amount()]: '_pay_product_amount',
      [r.generalFlow.help.index()]: '_help',
    }[pathname] || '';
  if (matchPath(pathname, { path: r.generalFlow.oppPayment({}), exact: true })) {
    transKey = '_payment';
  }

  /**
   * Check if widget is loaded, cancel this operation if not production
   */
  const isChatInit = (): void => {
    setIsFcInit(window.fcWidget?.isInitialized() || false);
  };
  const handle = setInterval(isChatInit, 250);
  if (Config.NODE_ENV !== 'production') {
    clearInterval(handle);
  }

  // On init, add user properties
  React.useEffect(() => {
    if (isFcInit) {
      clearInterval(handle);
      window.fcWidget?.on('user:created', () => {
        window.fcWidget?.user?.setProperties?.({ source: 'General' });
        window.fcWidget?.user?.update?.({ source: 'General' });
      });
    }
  }, [isFcInit]);

  // On init and homesituation type changed, add user properties
  React.useEffect(() => {
    if (isFcInit && typeof homeSituation !== 'undefined') {
      window.fcWidget?.user?.setProperties?.({ origin: homeSituation });
      window.fcWidget?.user?.update?.({ origin: homeSituation });
    }
  }, [isFcInit, homeSituation]);

  React.useEffect(() => {
    // trigger GA event
    if (isModalActive) {
      trackEvent({
        eventCategory: 'Help popup opened',
        eventAction: pathname,
      });
    }
  }, [isModalActive]);

  return (
    <>
      <div className={'general-help--trigger'} onClick={() => setIsModalActive(true)}>
        <IconInfo /> <Translate id={'request_flow.help.more_info'} />
      </div>
      <Dialog isActive={isModalActive}>
        <Modal
          header={
            <div className={'help-header text--center'}>
              <IconQuestionBubble />
              <Translate id={'request_flow.help.title'} />
            </div>
          }
          onClick={() => setIsModalActive(false)}
          style={'blue'}
          open={isModalActive}
        >
          <div className={'help-content text--center'}>
            <Heading size={3}>
              <Translate id={'request_flow.help.big_text' + transKey} />
            </Heading>
            <div
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: String(translate('request_flow.help.body_text' + transKey)),
              }}
            />
            <div className={'pt-1'}>
              <Button buttonStyle={'secondary'} extraClasses={'open-chat'} onClick={() => toggleChat()}>
                <Translate id={'request_flow.help.chat'} />
              </Button>
              <Button buttonStyle={'secondary'} extraClasses={'close-chat'} onClick={() => toggleChat()}>
                <Translate id={'request_flow.help.chat_close'} />
              </Button>
            </div>
          </div>
        </Modal>
      </Dialog>
    </>
  );
};

export const toggleChat = (): void => {
  const bodyClasses = document.body.classList;
  if (!bodyClasses.contains('chat--is-visible')) {
    bodyClasses.add('chat--is-visible');
    if (window.fcWidget && typeof window.fcWidget.open !== 'undefined') {
      setTimeout(() => {
        window.fcWidget.open();
      }, 350);
    }
  } else {
    bodyClasses.remove('chat--is-visible');
  }
};
